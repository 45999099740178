export class SearchModel {

  constructor(searchByName: boolean, searchByTag: boolean, searchByDescription: boolean, searchByModel: boolean) {
    this.searchByName = searchByName;
    this.searchByTag = searchByTag;
    this.searchByDescription = searchByDescription;
    this.searchByModel = searchByModel;
  }

  searchByName: boolean;
  searchByTag: boolean;
  searchByDescription: boolean;
  searchByModel: boolean;

}
