import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from "./modules/auth/service/auth-guard.service";

const routes: Routes = [
    {
      path: 'auth',
      loadChildren: () =>
        import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
      path: '',
      canActivate: [AuthGuardService],
      loadChildren: () =>
        import('./modules/layout/layout.module').then((m) => m.LayoutModule),
    }
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
