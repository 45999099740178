import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {JwtInterceptor} from "./interceptor/jwt.interceptor";
import {TokenStorageService} from "./modules/auth/service/token-storage.service";
import {ClipboardModule} from "ngx-clipboard";
import {NgToastModule} from "ng-angular-popup";
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    // FormsModule,
    // CommonModule,
    // BrowserAnimationsModule,
    // NgbModule,
    // NgxPaginationModule,
    AppRoutingModule,
    // AutocompleteLibModule,
    HttpClientModule,
    ClipboardModule,
    // ReactiveFormsModule,
    HttpClientJsonpModule,
    NgToastModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
      deps: [TokenStorageService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
