import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TokenStorageService} from "../modules/auth/service/token-storage.service";

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {

  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.token.getToken();

    if (authToken) {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ` + authToken,
      });

      const paramReq = req.clone({
        headers: httpHeaders
      });
      return next.handle(paramReq);
    } else {
      return next.handle(req);
    }
  }
}
