import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {TokenStorageService} from "./token-storage.service";
import {ResponseModel} from "../model/response-model";
import {AuthService} from "./auth.service";
import {GlobalComponent} from "../../../global-component";
import {UserType} from "../../layout/model/enum/user-type";

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {

  constructor(private tokenStorage: TokenStorageService,
              private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let link = route.queryParams['link'];

    if (link != null){
      GlobalComponent.currentUser = 'link: ' + link;
      this.authService.loginByLink(link).subscribe(
        (data: ResponseModel) => {
          if (data.answer === 'ok') {
            let model = data.model;

            this.saveConstant(model.type);

            this.tokenStorage.saveToken(model.authToken, model.expiresIn, model.type);

            this.router.navigate(['/']);
          }
        },
        err => {
            console.log("log error by link");
        }
      );
    }

    const currentToken = this.tokenStorage.getToken();
    const expiresIn = this.tokenStorage.getExpiresIn();
    const userType = this.tokenStorage.getType();

    if (currentToken && expiresIn && userType) {
      let dateExpiresIn = new Date(expiresIn);
      if (dateExpiresIn < new Date()) {
        this.tokenStorage.logout();
        this.router.navigate(['/auth/login']);
        return false;
      }
      this.saveConstant(userType);
      return true;
    }

    this.tokenStorage.logout();
    return false;
  }

  private saveConstant(type: string){
    switch (type) {
      case 'r':
        GlobalComponent.currentUserType = UserType.ADMIN;
        break;
      case 'g':
        GlobalComponent.currentUserType = UserType.GUEST;
        break;
      case 't':
        GlobalComponent.currentUserType = UserType.TEAM;
        break;
      case 'c':
        GlobalComponent.currentUserType = UserType.CLIENT;
        break;
      default:
        break;
    }
  }
}
