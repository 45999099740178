import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router} from '@angular/router';
import {filter} from "rxjs/operators";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  //   // this.setUpAnalytics();
  }

  // setUpAnalytics() {
  //   this.router.events.pipe(filter(event => event instanceof NavigationEnd))
  //     .subscribe(event => {
  //       // "event" here is now of type "NavigationEnd"
  //       if (event instanceof GuardsCheckEnd) {
  //         gtag('config', 'G-Z27GZR774G',
  //           {
  //             page_path: event.urlAfterRedirects
  //           }
  //         );
  //       }
  //     });
  // }
}
