import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

const TOKEN_KEY = 'auth-token';
const EXPIRES_IN = 'expires-in';
const TYPE = 'type';

@Injectable({providedIn: 'root'})
export class TokenStorageService {

  constructor(private router: Router) {
  }

  logout(): void {
    window.sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  public saveToken(token: string, expiresIn: string, type: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    window.sessionStorage.removeItem(EXPIRES_IN);
    window.sessionStorage.setItem(EXPIRES_IN, expiresIn);
    window.sessionStorage.removeItem(TYPE);
    window.sessionStorage.setItem(TYPE, type);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getExpiresIn(): string | null {
    return window.sessionStorage.getItem(EXPIRES_IN);
  }

  public getType(): string | null {
    return window.sessionStorage.getItem(TYPE);
  }
}
