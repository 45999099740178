import {SearchModel} from "./modules/layout/model/search-model";
import {UserType} from "./modules/layout/model/enum/user-type";

export class GlobalComponent {
  public static searchModel: SearchModel = new SearchModel(true, false, false, false);
  public static link = 'https://library-maxwave3d.com?link=';
  public static imagePath = 'https://modelsimages.s3.eu-north-1.amazonaws.com/';
  public static downloadPath = 'https://img.library-maxwave3d.com/img/';
  public static currentUser = '';
  public static currentUserType: UserType;

  public static enumSelector(definition: any): any {

    return Object.keys(definition)
      .map(key => ({id: definition[key], name: key}));
  }
}
