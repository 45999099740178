import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserModel} from "../model/user-model";
import {ResponseModel} from "../model/response-model";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

const API_USERS_URL = `${environment.apiUrl}/auth`;
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  login(email: string, password: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/authenticate`, {
      email,
      password,
    }, httpOptions);
  }

  logout(): Observable<ResponseModel>  {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/logout`, {
    }, httpOptions);
  }


  loginByLink(link: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/link/` + link, httpOptions);
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<ResponseModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<ResponseModel>(`${API_USERS_URL}/foundMe`, {
      headers: httpHeaders,
    });
  }

  registerUser(user: UserModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/register`, user, httpOptions);
  }

}
